export const pages = [
  { index: 0, name: 'overview.md', route: '/overview' },
  { index: 1, name: 'experience.md', route: '/experience' },
  { index: 2, name: 'education.md', route: '/education' },
  { index: 3, name: 'skills.md', route: '/skills' },
  { index: 4, name: 'certificates.md', route: '/certificates' },
  { index: 5, name: 'volunteering.md', route: '/volunteering' },
  { index: 6, name: 'accomplishments.md', route: '/accomplishments' },
  { index: 7, name: 'languages.md', route: '/languages' },
  // { index: 8, name: 'ChatGPT.md', route: '/ChatGPT' },
  // { index: 9, name: 'projects.md', route: '/projects' },
];
