import {
  FaMedium,
  FaBlog,
  FaGithub,
  FaLinkedin,
  FaEnvelope, FaGoogle, FaInstagramSquare, FaInstagram, FaSnapchatGhost, FaFacebook, FaFacebookF, FaTiktok,
} from "react-icons/fa";

export const links = [
  {
    index: 0,
    title: "Let's connect and grow our professional networks on LinkedIn",
    href: "https://www.linkedin.com/in/shubhamchawre/",
    icon: <FaLinkedin />,
  },
  {
    index: 1,
    title: "Would you like to connect via email? Let's stay in touch that way",
    href: "mailto:shubhamchawre@hotmail.com",
    icon: <FaEnvelope />,
  },
  {
    index: 2,
    title: "Connect with me on Instagram for behind-the-scenes of my life",
    href: "https://www.instagram.com/mr._.shubham",
    icon: <FaInstagram />,
  },
  {
    index: 3,
    title: "Are you part of the GDG community? Let's connect and stay updated!",
    href: "https://gdg.community.dev/gdg-brisbane/",
    icon: <FaGoogle />,
  },
  {
    index: 4,
    title: "Want to see what I'm up to?",
    href: "https://www.snapchat.com/add/shubhamchawre/",
    icon: <FaSnapchatGhost />,
  },
  {
    index: 5,
    title: "Want to stay in touch?",
    href: "https://www.facebook.com/shubhamchawre",
    icon: <FaFacebookF />,
  },
  {
    index: 5,
    title: "Let's connect and share some fun videos on TikTok!",
    href: "https://www.tiktok.com/@mr._.chawre",
    icon: <FaTiktok />,
  },
];
